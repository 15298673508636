const getPhoneNumber = (phoneNumber: string): string => {
    return phoneNumber
        .replace(/^\+/, '')
        .replace(
            /(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})/,
            '+$1 ($2$3$4) $5$6$7-$8$9-$10$11',
        );
};

export const formats = {
    phone: getPhoneNumber,
};
