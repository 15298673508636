import { styled, css } from 'styled-components';

import { StyledSizeElement } from '@/types/interfaces';

import { rowYMiddle } from '../styled';

const sizeLarge = css`
    .ant-input-affix-wrapper {
        height: 64px;

        .ant-input {
            height: 100%;
            padding-left: 20px;
            font-size: 2.8rem;
            font-weight: 370;
            border-radius: 8px;
        }
    }

    .addonAfter {
        top: 0;
        right: 20px;
        height: 64px;
    }
`;

const sizeMedium = css`
    &.mask-input {
        input {
            height: 44px;
        }
    }

    .ant-input-affix-wrapper {
        height: 44px;

        .ant-input {
            height: 100%;
            font-size: 16px;
        }
    }
`;

const sizeSmall = css`
    &.mask-input {
        input {
            height: 44px;
        }
    }
`;

const focusCssInput = css`
    box-shadow: none;
`;

const hoverCssInput = css`
    box-shadow: none;
    border: 1px solid #00d9de;
`;

const cssInput = css`
    outline: none;
    background-color: #f6f8fa;
    border-radius: 8px;
    padding-left: 12px !important;
    font-size: 1rem;
`;

export const StyledInputWrapper = styled.div<{ $size: StyledSizeElement; $isIconBtn?: boolean }>`
    position: relative;

    &.mask-input {
        &.readOnly {
            cursor: not-allowed;
        }

        input {
            border: 0.5px solid #ececf0;
            padding: 0;
            border-radius: 8px;

            ${cssInput}
            &:hover {
                ${hoverCssInput}
            }

            &:focus {
                ${focusCssInput}
                border: 1px solid #00d9de !important;
            }
        }

        &.error {
            input {
                border: 1px solid ${({ theme }) => theme.color.error} !important;
            }
        }
    }

    label {
        display: block;
        font-size: 14px;
        margin-bottom: 8px;
    }

    .ant-input-affix-wrapper-focused {
        ${focusCssInput}
        border: 1px solid #00d9de !important;
    }

    .ant-input {
        ${cssInput}
        height: 44px;

        &[readonly] {
            user-select: none;
            pointer-events: none;
        }

        &:hover {
            ${hoverCssInput};
        }

        &:focus {
            ${hoverCssInput};
        }
    }

    .ant-input-clear-icon-has-suffix {
        ${rowYMiddle};
        padding-right: 5px;
        border-right: 2px solid #ececf0;
    }

    .ant-input-affix-wrapper {
        border: 0.5px solid #ececf0;
        padding: 0;
        border-radius: 8px;
        background: #f6f8fa;

        &:hover {
            ${hoverCssInput}
        }

        .ant-input {
            &:hover {
                border: none !important;
            }

            &:focus {
                border: none !important;
            }
        }

        &.with-icon input {
            &:focus {
                border-radius: 8px 0 0 8px !important;
                border-right: none !important;
            }
        }

        .ant-input-suffix {
            cursor: pointer;
            background-color: #f6f8fa;
            margin-left: 0;
            border-radius: 8px;
            padding-right: 12px;

            svg {
                cursor: pointer;
            }
            ${(props) => props.$isIconBtn && 'cursor: pointer;'}
        }
    }

    .addonAfter {
        font-size: 30px;
        position: absolute;
        color: #6f7582;
        align-items: center;
        display: flex;
    }
    ${(props) => props.$size === 'lg' && sizeLarge};
    ${(props) => props.$size === 'md' && sizeMedium};
    ${(props) => props.$size === 'sm' && sizeSmall};
`;
