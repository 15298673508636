import { ReactNode } from 'react';

import * as StyledLayout from '../content/styled';

import * as Styled from './styled';

type TProps = {
    children: ReactNode;
    noMarginDesc?: boolean;
    noMarginMob?: boolean;
    noMargin?: boolean;
    header?: ReactNode;
    footer?: ReactNode;
};

export const DefaultLayout = ({
    children,
    noMarginDesc = false,
    noMarginMob = false,
    noMargin = false,
    header,
    footer,
}: TProps) => {
    return (
        <Styled.Wrapper>
            {header}
            <Styled.ContainerWrapper $noMarginDesc={noMarginDesc} $noMarginMob={noMarginMob} $noMargin={noMargin}>
                {children}
            </Styled.ContainerWrapper>
            {footer}
        </Styled.Wrapper>
    );
};

DefaultLayout.CardConent = StyledLayout.CardConent;
DefaultLayout.Content = StyledLayout.Container;
DefaultLayout.PageWrapperContent = StyledLayout.DynamicPageWrapper;
