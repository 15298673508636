import { Input, InputRef } from 'antd';
import { MaskedInput } from 'antd-5-mask-input';
import { MaskType } from 'antd-5-mask-input/build/module/lib/MaskedInput';
import cn from 'classnames';
import { ComponentProps, FC, ReactElement, Ref } from 'react';

import { StyledSizeElement } from '@/types/interfaces';

import { StyledInputWrapper } from './styled';

type TProps = Omit<ComponentProps<'input'>, 'size'> & {
    label?: string;
    error?: string | ReactElement;
    size?: StyledSizeElement;
    icon?: JSX.Element;
    hint?: string | ReactElement;
    mask?: MaskType | string;
    addonAfter?: string | ReactElement;
    isAutocomplete?: boolean;
    classWrapper?: string;
    myRef?: Ref<InputRef>;
    allowClear?: boolean | { clearIcon: React.ReactNode };
    maxLength?: number;
    inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
    isIconBtn?: boolean;
};

export const CustomInput: FC<TProps> = ({
    // Id,
    name,
    label,
    value,
    type = 'text',
    placeholder,
    error = '',
    size = 'md',
    icon,
    isIconBtn = false,
    readOnly,
    // Hint,
    mask = '',
    onChange,
    disabled = false,
    tabIndex = 0,
    isAutocomplete = false,
    addonAfter = '',
    onBlur,
    classWrapper,
    autoFocus,
    inputMode,
    pattern,
    myRef,
    allowClear,
    maxLength,
    onKeyUp,
}) => {
    return (
        <StyledInputWrapper
            $isIconBtn={isIconBtn}
            $size={size}
            className={cn({
                ['mask-input']: mask,
                [String(classWrapper)]: classWrapper,
                ['readOnly']: readOnly,
                ['error']: error,
            })}
        >
            {label && <label htmlFor={name}>{label}</label>}
            {!mask ? (
                <Input
                    id={name}
                    name={name}
                    onChange={onChange}
                    value={value}
                    suffix={icon}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    disabled={disabled}
                    tabIndex={tabIndex}
                    onBlur={onBlur}
                    autoComplete={!isAutocomplete ? 'off' : 'on'}
                    autoFocus={autoFocus}
                    type={type}
                    className={icon ? 'with-icon' : ''}
                    inputMode={inputMode}
                    ref={myRef}
                    allowClear={allowClear}
                    maxLength={maxLength}
                    onKeyUp={onKeyUp}
                />
            ) : (
                <MaskedInput
                    id={name}
                    name={name}
                    onChange={onChange}
                    readOnly={readOnly}
                    value={value as string}
                    mask={mask}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    disabled={disabled}
                    tabIndex={tabIndex}
                    autoComplete={!isAutocomplete ? 'off' : 'on'}
                    autoFocus={autoFocus}
                    type={type}
                    inputMode={inputMode}
                    pattern={pattern}
                    ref={myRef}
                    onKeyUp={onKeyUp}
                />
            )}
            {error && <p className="error"> {error} </p>}
            {addonAfter && <div className="addonAfter">{addonAfter}</div>}
        </StyledInputWrapper>
    );
};
