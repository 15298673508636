import { styled } from 'styled-components';

import { Button } from '@/shared/ui/button';
import { mq } from '@/shared/ui/styled';

export const Wrapper = styled(Button).attrs({
    variant: 'primary',
    fontSize: 'md',
})`
    margin-right: 12px;
    padding-left: 11px;
    padding-right: 14px;
    position: relative;
    height: 100%;

    & > svg {
        margin-right: 12px;
    }

    & {
        display: none;

        ${mq.gt.sm} {
            display: flex;
        }
    }
`;
