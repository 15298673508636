import { styled } from 'styled-components';

import { mq, rowBothMiddle } from '@/shared/ui/styled';

import HeaderNavArea from './nav-area';
import HeaderPhoneArea from './phone-area';
import HeaderSearchArea from './search-area';

export const StyledHeader = styled.header`
    background-color: ${({ theme }) => theme.color.secondary};
    box-shadow:
        0 0 2px rgb(0 0 0 / 3%),
        0 2px 2px rgb(0 0 0 / 6%);
    ${mq.lt.md} {
        padding-bottom: 0;
    }
`;

export const StyledHeaderStickyContainer = styled.div`
    background-color: ${({ theme }) => theme.color.secondary};
    grid-area: sticky;
    display: grid;
    grid-template-columns: 5fr 3fr;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    position: relative;
    padding: 16px 0;
    grid-row-gap: 1rem;
    grid-template-areas:
        'nav phone'
        'search user-menu';

    ${mq.lt.lg} {
        grid-template-columns: 7fr 3fr;
    }
    ${mq.lt.md} {
        position: fixed;
        padding: 16px 17px;
        grid-template-areas:
            'nav phone'
            'search search';
        grid-row-gap: 5px;
    }
`;

export const StyledHeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
    grid-template-areas:
        'sticky sticky'
        'search search';

    ${mq.lt.md} {
        padding-top: 0;
        display: block;
    }
`;

export const HeaderNavContainer = styled(HeaderNavArea)`
    ${mq.lt.md} {
        grid-area: nav;
    }
`;

export const HeaderPhoneContainer = styled(HeaderPhoneArea)`
    ${mq.gt.md} {
        grid-area: phone;
    }
`;

export const HeaderSearchContainer = styled(HeaderSearchArea)`
    grid-area: search;
    display: none;

    &.hidden-md {
        display: flex;
    }

    ${mq.lt.md} {
        display: flex;

        &.hidden-md {
            button {
                display: none;
            }
        }
    }
`;

export const StyledHeaderOnlyLogoContainer = styled.header`
    ${rowBothMiddle};
    padding-top: 34px;
`;
