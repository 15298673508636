import { Grid } from 'antd';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { DropdownMenu } from '../menu-list';

import { SearchContainer } from './styled';

const { useBreakpoint } = Grid;

const SearchProductsInput = dynamic(() => import('../../../components/modules/search-products-input'), { ssr: false });

type TProps = {
    className?: string;
};

const HeaderSearchArea: React.FC<TProps> = ({ className }) => {
    const screen = useBreakpoint();

    const router = useRouter();

    const [searchValue, setSearchValue] = useState<string>('');

    const [isContentHidden, setIsContentHidden] = useState<boolean>(false);

    const checkHowManyDidWeScrollY = () => {
        if (window.pageYOffset >= 50) {
            setIsContentHidden(true);
        } else {
            setIsContentHidden(false);
        }
    };

    useEffect(() => {
        if (!screen.md) {
            window.addEventListener('scroll', checkHowManyDidWeScrollY);
        }

        return () => {
            setIsContentHidden(false);

            return window.removeEventListener('scroll', checkHowManyDidWeScrollY);
        };
    }, [screen]);

    useEffect(() => {
        if (router.query.q || searchValue) {
            setSearchValue(router.query.q ? String(router.query.q) : '');
        }
    }, [router]);

    if (isContentHidden) return <></>;

    return (
        <SearchContainer className={className}>
            <DropdownMenu />
            <div style={{ width: '100%' }}>
                <SearchProductsInput searchValue={searchValue} setSearchValue={setSearchValue} />
            </div>
        </SearchContainer>
    );
};

export default HeaderSearchArea;
