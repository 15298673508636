import dynamic from 'next/dynamic';
import React from 'react';

import { Layout } from '@/shared/ui/layout';
import { Logo } from '@/shared/ui/logo';

import {
    StyledHeader,
    StyledHeaderContainer,
    HeaderNavContainer,
    HeaderPhoneContainer,
    HeaderSearchContainer,
    StyledHeaderStickyContainer,
    StyledHeaderOnlyLogoContainer,
} from './styled';

const HeaderUserMenuArea = dynamic(() => import('./user-menu-area'), { ssr: false });

export const Header: React.FC = () => {
    return (
        <StyledHeader>
            <Layout.Content>
                <StyledHeaderContainer>
                    <StyledHeaderStickyContainer>
                        <HeaderNavContainer />
                        <HeaderPhoneContainer />
                        <HeaderUserMenuArea />
                        <HeaderSearchContainer className="hidden-md" />
                    </StyledHeaderStickyContainer>
                </StyledHeaderContainer>
            </Layout.Content>
        </StyledHeader>
    );
};

export const HeaderLogoOnly: React.FC = () => {
    return (
        <StyledHeaderOnlyLogoContainer>
            <Logo />
        </StyledHeaderOnlyLogoContainer>
    );
};
