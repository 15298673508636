import { Dropdown } from 'antd';
import React, { useState } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';
import { BurgerIcon } from '@/shared/ui/icons';

import { useCatalogList } from '../../hooks/use-catalog-list';

import * as Styled from './styled';

export const DropdownMenu = () => {
    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);

    const { t } = useTranslate();

    const handleChangeStatusDropdown = () => {
        setIsOpenDropdown((prev) => !prev);
    };

    const { menuList, MenuWrapper } = useCatalogList({ onItemClick: handleChangeStatusDropdown });

    return (
        <Dropdown
            menu={{ items: menuList }}
            dropdownRender={(menu) => <MenuWrapper>{menu}</MenuWrapper>}
            open={isOpenDropdown}
            trigger={['click']}
            onOpenChange={handleChangeStatusDropdown}
        >
            <div>
                <Styled.Wrapper>
                    <BurgerIcon />

                    {t('Каталог')}
                </Styled.Wrapper>
            </div>
        </Dropdown>
    );
};
