import { useRouter } from 'next/router';
import React, { forwardRef } from 'react';

import { StyledDropdownModalButton, StyledDropdownModalContainer, StyledDropdownModalTitle } from './styled';

type TProps = {
    title?: string;
    textBtn?: string;
    children: React.ReactNode;
    href?: string;
    withBg?: boolean;
    onBtnClick?: () => void;
};

export const DropdownModalContainer = forwardRef<HTMLDivElement, TProps>(
    ({ children, textBtn, title, href = '/', withBg = true, onBtnClick }, ref) => {
        const { push } = useRouter();
        return (
            <StyledDropdownModalContainer withBg={withBg} ref={ref}>
                {title && <StyledDropdownModalTitle> {title} </StyledDropdownModalTitle>}
                {children}
                {textBtn && (
                    <StyledDropdownModalButton
                        onClick={() => {
                            onBtnClick?.();
                            push(href);
                        }}
                    >
                        {' '}
                        {textBtn}{' '}
                    </StyledDropdownModalButton>
                )}
            </StyledDropdownModalContainer>
        );
    },
);

DropdownModalContainer.displayName = 'DropdownModalContainer';
