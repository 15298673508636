import Link from 'next/link';
import React from 'react';

import { LogoIcon } from '@/shared/ui/icons';

import { StyledLogoWrapper } from './styled';

type TProps = {
    href?: string;
    color?: string;
};

export const Logo: React.FC<TProps> = ({ href = '/', color }) => {
    return (
        <StyledLogoWrapper>
            <Link href={href}>
                <LogoIcon color={color} />
            </Link>
        </StyledLogoWrapper>
    );
};
