import React from 'react';

import { TSvgIcon } from '../types';

export const LocationIcon: TSvgIcon = ({ color = '#A9ADBC', width = 12 }) => {
    return (
        <svg width={width} viewBox="0 0 12 17" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M6.0002 0.666504C2.7752 0.666504 0.16687 3.27484 0.16687 6.49984C0.16687 9.97484 3.8502 14.7665 5.36687 16.5915C5.7002 16.9915 6.30854 16.9915 6.64187 16.5915C8.1502 14.7665 11.8335 9.97484 11.8335 6.49984C11.8335 3.27484 9.2252 0.666504 6.0002 0.666504ZM6.0002 8.58317C4.8502 8.58317 3.91687 7.64984 3.91687 6.49984C3.91687 5.34984 4.8502 4.4165 6.0002 4.4165C7.1502 4.4165 8.08354 5.34984 8.08354 6.49984C8.08354 7.64984 7.1502 8.58317 6.0002 8.58317Z" />
        </svg>
    );
};
