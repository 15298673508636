import React from 'react';

import { TSvgIcon } from '../types';

export const UserDataIcon: TSvgIcon = ({ width = 16, color = '#A9ADBC' }): JSX.Element => {
    return (
        <svg width={width} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6667 3.33333V12.6667H3.33333V3.33333H12.6667ZM12.6667 2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM8 8C6.9 8 6 7.1 6 6C6 4.9 6.9 4 8 4C9.1 4 10 4.9 10 6C10 7.1 9.1 8 8 8ZM8 5.33333C7.63333 5.33333 7.33333 5.63333 7.33333 6C7.33333 6.36667 7.63333 6.66667 8 6.66667C8.36667 6.66667 8.66667 6.36667 8.66667 6C8.66667 5.63333 8.36667 5.33333 8 5.33333ZM12 12H4V10.98C4 9.31333 6.64667 8.59333 8 8.59333C9.35333 8.59333 12 9.31333 12 10.98V12ZM5.54 10.6667H10.46C10 10.2933 8.87333 9.92 8 9.92C7.12667 9.92 5.99333 10.2933 5.54 10.6667V10.6667Z" />
        </svg>
    );
};
