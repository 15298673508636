import React from 'react';

import { TSvgIcon } from '../types';

export const Location2Icon: TSvgIcon = ({ width = 16, color = '#A9ADBC' }): JSX.Element => {
    return (
        <svg width={width} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M8 1.33325C5.42 1.33325 3.33334 3.41992 3.33334 5.99992C3.33334 9.49992 8 14.6666 8 14.6666C8 14.6666 12.6667 9.49992 12.6667 5.99992C12.6667 3.41992 10.58 1.33325 8 1.33325ZM4.66667 5.99992C4.66667 4.15992 6.16 2.66659 8 2.66659C9.84 2.66659 11.3333 4.15992 11.3333 5.99992C11.3333 7.91992 9.41334 10.7933 8 12.5866C6.61334 10.8066 4.66667 7.89992 4.66667 5.99992Z" />
            <path d="M8 7.66658C8.92048 7.66658 9.66667 6.92039 9.66667 5.99992C9.66667 5.07944 8.92048 4.33325 8 4.33325C7.07953 4.33325 6.33334 5.07944 6.33334 5.99992C6.33334 6.92039 7.07953 7.66658 8 7.66658Z" />
        </svg>
    );
};
