import React, { FC, ReactNode } from 'react';

import { LinkBlock } from '@/components/elements/link-block';

import { theme } from '@/shared/theme';
import { ArrowIcon } from '@/shared/ui/icons';

import * as Styled from './styled';

type TProps = {
    href: string;
    children?: ReactNode;
    onItemClick?: () => void;
};

export const MenuItem: FC<TProps> = ({ onItemClick, href, children }) => {
    return (
        <Styled.Wrapper onClick={onItemClick}>
            <LinkBlock href={href}>
                <span>{children}</span>
                <span>
                    <ArrowIcon color={theme.color.neutral300} />
                </span>
            </LinkBlock>
        </Styled.Wrapper>
    );
};
