import React from 'react';

import { TSvgIcon } from '../types';

export const BlogIcon: TSvgIcon = ({ width = 16, color = '#A9ADBC' }): JSX.Element => {
    return (
        <svg width={width} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1.33325H4C3.26666 1.33325 2.66666 1.93325 2.66666 2.66659V13.3333C2.66666 14.0666 3.26666 14.6666 4 14.6666H12C12.7333 14.6666 13.3333 14.0666 13.3333 13.3333V2.66659C13.3333 1.93325 12.7333 1.33325 12 1.33325ZM6 2.66659H7.33333V5.99992L6.66666 5.49992L6 5.99992V2.66659ZM12 13.3333H4V2.66659H4.66666V8.66659L6.66666 7.16659L8.66666 8.66659V2.66659H12V13.3333Z" />
        </svg>
    );
};
