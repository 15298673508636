import { TErrorData, TReadParamsProperties } from '@/shared/api';
import { ResourceService } from '@/shared/api/base-services/resource-service';

export const UserArentaService = ResourceService('callback');

export type TResponseUserCallback = {
    success: string;
};

export const getUserCallback = async (
    phoneNumber = '',
    utmSource?: string,
): Promise<TResponseUserCallback | TErrorData> => {
    let phone = phoneNumber.replace(/[-()\s]/gi, '');

    phone = '5' + phone.slice(2);

    const responseIsValidIin = await UserArentaService.command<TResponseUserCallback, TReadParamsProperties>({
        command: phone,
        data: { utm_source: utmSource },
    });

    return responseIsValidIin;
};
