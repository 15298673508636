import React from 'react';

import { TSvgIcon } from '../types';

export const HowWorkIcon: TSvgIcon = ({ width = 16, color = '#A9ADBC' }): JSX.Element => {
    return (
        <svg width={width} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3333 2H4.66667C3.93334 2 3.33334 2.6 3.33334 3.33333V14L8 12L12.6667 14V3.33333C12.6667 2.6 12.0667 2 11.3333 2ZM11.3333 12L8 10.5467L4.66667 12V4C4.66667 3.63333 4.96667 3.33333 5.33334 3.33333H10.6667C11.0333 3.33333 11.3333 3.63333 11.3333 4V12Z" />
        </svg>
    );
};
