import { captureException } from '@sentry/browser';
import { useFormik } from 'formik';
import Link from 'next/link';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { useAppSelector } from '@/store/hooks';

import { PHONE_LENGTH, PRIVACY_POLICY_PATH } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';
import { utmSourceService } from '@/shared/hooks/use-utm-source-service';
import { Button } from '@/shared/ui/button';
import { Checkbox } from '@/shared/ui/checkbox';
import { CustomInput } from '@/shared/ui/input';
import { copyValueToClipboard } from '@/shared/utils/copy-value-to-clipboard';
import { formats } from '@/shared/utils/formats';

import { getUserCallback } from '../api/callback-service';

import * as Styled from './styled';

export const Callback: React.FC = () => {
    const { t } = useTranslate();
    const [isSubmitLoaded, setIsSubmitLoaded] = useState(false);
    const [result, setResult] = useState<null | { data: boolean }>(null);
    const callTrackPhone = useAppSelector((state) => state.calltrack.callTrackPhone);
    const formattedNumber = formats.phone(callTrackPhone);
    const utmSource = utmSourceService.getParamUtmSource();

    const formik = useFormik({
        initialValues: {
            phone: '',
            accept: true,
        },
        validationSchema: Yup.object().shape({
            phone: Yup.string().test('phone', t('Невалидный номер'), (value) => {
                return value?.replace(/\D+/g, '').length === PHONE_LENGTH;
            }),
        }),
        validateOnMount: true,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: async (values) => {
            setIsSubmitLoaded(true);

            try {
                await getUserCallback(values.phone, utmSource);

                setResult({ data: true });
            } catch (err) {
                captureException(err);

                setResult({ data: false });
            }

            setIsSubmitLoaded(false);
        },
    });

    if (result && !result.data) {
        return (
            <Styled.ModalWrapper>
                <div className="hint">{t('Возникла ошибка. Обратитесь в службу поддержки')}.</div>
            </Styled.ModalWrapper>
        );
    }

    if (result && result.data) {
        return (
            <Styled.ModalWrapper>
                <div className="hint">
                    {t('Благодарим за обращение. Наш менеджер свяжется с вами в ближайшее время')}.
                </div>
            </Styled.ModalWrapper>
        );
    }

    const copyPhoneValueToСlipboard = () => {
        copyValueToClipboard(formattedNumber);
    };

    return (
        <Styled.Wrapper>
            <Styled.InfoMessage>
                {t('Оставьте свой номер телефона и наш менеджер свяжется с вами в ближайшее время')}
            </Styled.InfoMessage>
            <div className="form">
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ marginBottom: '20px' }}>
                        <CustomInput
                            label={t('Ваш телефон')}
                            name="phone"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                            placeholder="+7 (000) 000-00-00"
                            inputMode="tel"
                            mask={
                                //  https://imask.js.org/guide.html#masked-pattern
                                '+7 (000) 000-00-00'
                            }
                        />
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <Button
                            type="submit"
                            fontSize="lg"
                            variant="primary"
                            stretch
                            disabled={!formik.isValid || !formik.values.accept}
                            isLoading={isSubmitLoaded}
                            aria-label={t('Перезвоните мне')}
                        >
                            {t('Перезвоните мне')}
                        </Button>
                    </div>
                    <Checkbox name="accept" checked={formik.values.accept} onChange={formik.handleChange}>
                        <p className="terms-accept">
                            {t('Я ознакомлен(а) и принимаю')} <br />
                        </p>
                    </Checkbox>
                    <div className="link-doc">
                        <Link href={PRIVACY_POLICY_PATH} target="_blank">
                            {t('Политику в отношении обработки персональных данных')}
                        </Link>
                    </div>
                    <Styled.RecallUs onClick={copyPhoneValueToСlipboard}>
                        {t('Или позвоните нам')}&nbsp;
                        <Link href={`tel:${callTrackPhone}`}>{formattedNumber}</Link>
                    </Styled.RecallUs>
                </form>
            </div>
        </Styled.Wrapper>
    );
};
