import React from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { showModal } from '@/store/reducers/modal/slice';

import { Callback } from '@/features/callback';

import { useTranslate } from '@/shared/hooks/use-translate';
import { Button } from '@/shared/ui/button';
import { copyValueToClipboard } from '@/shared/utils/copy-value-to-clipboard';
import { formats } from '@/shared/utils/formats';

import { PhoneContainer, PhoneLink } from './styled';

type TProps = {
    className?: string;
};

const HeaderPhoneArea: React.FC<TProps> = ({ className }) => {
    const dispatch = useAppDispatch();

    const { t } = useTranslate();

    const callTrackPhone = useAppSelector((state) => state.calltrack.callTrackPhone);

    const formattedNumber = formats.phone(callTrackPhone);

    const copyPhoneValueToСlipboard = () => {
        copyValueToClipboard(formattedNumber);
    };

    const handleOpenModal = () => {
        dispatch(showModal({ size: 'Small', title: t('Закажите обратный звонок'), content: <Callback /> }));
    };

    return (
        <PhoneContainer className={className}>
            <PhoneLink onClick={copyPhoneValueToСlipboard}>{formattedNumber}</PhoneLink>
            <Button onClick={handleOpenModal} aria-label={t('Заказать звонок')}>
                {t('Заказать звонок')}
            </Button>
        </PhoneContainer>
    );
};

export default HeaderPhoneArea;
