import React from 'react';

import { TSvgIcon } from '../types';

export const ArrowUpIcon: TSvgIcon = ({ color = '#1F2547', width = 17 }): JSX.Element => {
    return (
        <svg width={width} viewBox="0 0 17 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.2548 9.80667L8.66815 7.22L6.08148 9.80667C5.82148 10.0667 5.40148 10.0667 5.14148 9.80667C4.88148 9.54667 4.88148 9.12667 5.14148 8.86667L8.20148 5.80667C8.46148 5.54667 8.88148 5.54667 9.14148 5.80667L12.2015 8.86667C12.4615 9.12667 12.4615 9.54667 12.2015 9.80667C11.9415 10.06 11.5148 10.0667 11.2548 9.80667Z"
                fill={color}
            />
        </svg>
    );
};
