import { styled } from 'styled-components';

import { mq, flexColumn } from '@/shared/ui/styled';

export const ModalWrapper = styled.div`
    color: #000;

    .hint {
        font-size: 1.2rem;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        text-align: center;
    }
    ${mq.lt.xl} {
        display: block;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    ${flexColumn};

    & .terms-accept {
        color: ${({ theme }) => theme.color.dark};
        font-size: ${({ theme }) => theme.fontSize.sm};
        max-width: 280px;
        line-height: 140%;

        span {
            margin-top: 8px;
            color: ${({ theme }) => theme.color.primary};
            line-height: 100%;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .link-doc {
        margin-left: 23px;

        a {
            margin-top: 8px;
            color: ${({ theme }) => theme.color.primary};
            line-height: 100%;
            text-decoration: underline;
            cursor: pointer;
            font-size: ${({ theme }) => theme.fontSize.sm};
        }
    }
`;

export const InfoMessage = styled.p`
    color: ${({ theme }) => theme.color.dark};
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin-bottom: 24px;
    line-height: 130%;
`;

export const RecallUs = styled.p`
    color: ${({ theme }) => theme.color.dark};
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin-top: 24px;
    font-weight: 500;
    cursor: pointer;
`;
