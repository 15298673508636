import { Grid } from 'antd';
import React, { FC } from 'react';

const { useBreakpoint } = Grid;

type TProps = {
    children: React.ReactNode;
    showIf: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
};

export const ShowContentByBreakPoint: FC<TProps> = ({ children, showIf }) => {
    const screen = useBreakpoint();

    return !screen[showIf] ? <>{children}</> : <></>;
};
