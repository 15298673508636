export const joinToUri = (...chunks: (string | number)[]): string => {
    return chunks
        .filter(Boolean)
        .map((chunk) => '/' + chunk)
        .join('');
};

export const getIRI = (entity: string, id: number | string): string => {
    return `/api/v1/${entity}/${id}`;
};

export const getIRIEntityId = (relation: string): number | null => {
    return Number(relation.split('/')?.pop()) || null;
};

/**
 * Склонение числительных
 * @param value
 * @param words
 */
export const numWord = (value: number, words: Array<string>): string => {
    value = Math.abs(value) % 100;

    const num = value % 10;

    if (value > 10 && value < 20) return words[2];

    if (num > 1 && num < 5) return words[1];

    if (num === 1) return words[0];

    return words[2];
};

export const cutString = (str: string, count: number): string => {
    if (!str) {
        return '';
    }

    return str.length < count ? str.substring(0, count - 1) : str;
};
