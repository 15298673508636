import { styled } from 'styled-components';

import { rowYMiddle } from '../styled';

export const StyledLinkBlock = styled.span<{ $hoverColor: 'primary' | 'default' }>`
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease-in;
    width: 100%;
    justify-content: space-between;
    ${rowYMiddle};

    :first-child {
        display: flex;
        gap: 10px;
    }

    svg {
        transition: fill 0.3s ease-in;
    }

    ${({ $hoverColor, theme }) =>
        $hoverColor === 'primary' &&
        `
    &:hover {
        color: ${theme.color.primary};
        transition: color 0.3s ease-out;
        svg {
            fill: ${theme.color.primary};
            transition: fill 0.3s ease-out;
        }
    }
    `}
`;
