import { MenuProps } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import React, { FC, forwardRef, ReactNode } from 'react';

import { useAppSelector } from '@/store/hooks';

import { useTranslate } from '@/shared/hooks/use-translate';
import { DropdownModalContainer } from '@/shared/ui/dropdown-modal';

import { MenuItem } from '../ui/menu-item';

type THook = (args: { onItemClick?: () => void }) => {
    menuList: ItemType[];
    MenuWrapper: FC<{ children: ReactNode }>;
};

export const useCatalogList: THook = ({ onItemClick }) => {
    const { t } = useTranslate();

    const mainCategories = useAppSelector((state) => state.rent.mainCategories.data);

    const cityUser = useAppSelector((state) => state.user.cityUser);

    const menuList: MenuProps['items'] = [
        ...mainCategories.map((category) => ({
            key: category.id,
            label: (
                <MenuItem href={`/${cityUser?.slug}/${category?.slug}`} onItemClick={onItemClick} key={category.id}>
                    {t(category.name)}
                </MenuItem>
            ),
        })),
        {
            key: 'another-goods',
            label: (
                <MenuItem href={`/${cityUser?.slug}`} onItemClick={onItemClick}>
                    {t('Остальные товары')}
                </MenuItem>
            ),
        },
    ];

    const MenuWrapper = forwardRef<HTMLDivElement, { children: ReactNode }>(({ children }, ref) => (
        <DropdownModalContainer
            textBtn={t('Перейти к каталогу')}
            href={`/${cityUser?.slug}`}
            onBtnClick={onItemClick}
            ref={ref}
        >
            {children}
        </DropdownModalContainer>
    ));

    MenuWrapper.displayName = 'MenuWrapper';

    return {
        menuList,
        MenuWrapper,
    };
};
