import React from 'react';

import { TSvgIcon } from '../types';

export const NotificationIcon: TSvgIcon = ({ width = 16, color = '#A9ADBC' }): JSX.Element => {
    return (
        <svg width={width} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M10 2.66659V7.33325H3.44667L3.05334 7.72659L2.66667 8.11325V2.66659H10ZM10.6667 1.33325H2C1.63334 1.33325 1.33334 1.63325 1.33334 1.99992V11.3333L4 8.66659H10.6667C11.0333 8.66659 11.3333 8.36659 11.3333 7.99992V1.99992C11.3333 1.63325 11.0333 1.33325 10.6667 1.33325ZM14 3.99992H12.6667V9.99992H4V11.3333C4 11.6999 4.3 11.9999 4.66667 11.9999H12L14.6667 14.6666V4.66659C14.6667 4.29992 14.3667 3.99992 14 3.99992Z" />
        </svg>
    );
};
