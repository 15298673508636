import { Grid } from 'antd';
import cn from 'classnames';
import Link from 'next/link';
import React, { FC, useState } from 'react';

import { TMenuItem } from '@/store/reducers/menus/types';

import { ArrowDown2Icon, ArrowUpIcon } from '@/shared/ui/icons';

import { StyledFooterNav, StyledFooterNavTitle, StyledFooterNavList } from './styled';

const { useBreakpoint } = Grid;

type TProps = {
    titleGroup: string;
    itemsLink: TMenuItem[];
};

export const FooterNavigate: FC<TProps> = ({ titleGroup, itemsLink }) => {
    const [open, setOpen] = useState(false);

    const screen = useBreakpoint();

    let newItems: TMenuItem[] = [];

    if (itemsLink.length > 5) {
        const count = Math.floor(itemsLink.length / 2);

        newItems = itemsLink.slice(count, itemsLink.length);

        itemsLink = itemsLink.slice(0, count);
    }

    return (
        <StyledFooterNav>
            <StyledFooterNavTitle onClick={() => setOpen((prev) => !prev)}>
                <div>{titleGroup}</div>
                {!screen.md && <div>{open ? <ArrowUpIcon color="#A9ADBC" /> : <ArrowDown2Icon />}</div>}
            </StyledFooterNavTitle>
            <StyledFooterNavList className={cn({ ['open']: open }, { ['mobile']: !screen.md })}>
                {itemsLink.map((item) => {
                    return (
                        <li key={item.name}>
                            <Link href={item.url}>{item.name}</Link>
                        </li>
                    );
                })}
            </StyledFooterNavList>
            {newItems.length > 0 && (
                <StyledFooterNavList className={cn({ ['open']: open }, { ['mobile']: !screen.md })}>
                    {newItems.map((item) => {
                        return (
                            <li key={item.name}>
                                <Link href={item.url}>{item.name}</Link>
                            </li>
                        );
                    })}
                </StyledFooterNavList>
            )}
        </StyledFooterNav>
    );
};
