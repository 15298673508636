import { FC } from 'react';

import { StyledBurger } from './styled';

type TProps = {
    onClick?: () => void;
    open: boolean;
};

export const Burger: FC<TProps> = ({ onClick, open }): JSX.Element => {
    return (
        <StyledBurger open={open} onClick={onClick}>
            <span></span>
            <span></span>
            <span></span>
        </StyledBurger>
    );
};
